.user-information {
  padding-top: 20px;

  .MuiInputBase-input.Mui-disabled {
    color: #000000;
  }

  .paper {
    padding: 20px 30px;

    @include mobile {
      padding: 20px 15px;
    }
  }

  .user-form-paper {
    width: 400px;

    @include mobile {
      width: 100%;
    }
  }

  .content {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;

    @include mobile {
      display: block;
    }

    .wallet {
      text-align: center;

      &-card {
        margin-top: 30px;
        background-color: $primary;
        color: white;
        border-radius: 10px;
        text-align: left;
        padding: 15px;
        display: flex;
        justify-content: space-between;
        width: 350px;

        @include mobile {
          margin-top: 5px;
          width: 100%;
        }

        .left-content {
          .top-title {
            margin: 0px;
            font-weight: 300;
          }

          .wallet-balance {
            margin-top: 5px;
            font-size: 30px;
            font-weight: 400;
          }

          .bottom-btns {
            margin-top: 60px;

            .fund-btn {
              background-color: $secondaryColor;
              padding: 5px 40px;
              color: $primary;
            }

            .bonus-btn {
              background-color: $secondaryColor;
              // padding: 5px 40px;
              color: $primary;
              margin-right: 5px;
            }

            .debit-btn {
              color: white;
            }
          }
        }

        .right-content {
          margin-left: 10px;
          align-items: center;
          align-self: center;
        }
      }
    }

    &-information {
      margin-top: 10px;

      @include mobile {
        margin-top: 20px;
      }

      p {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 5px;
      }

      .MuiFormLabel-root {
        font-size: 18px;
        font-weight: 600;
      }

      .form {
        // width: 60%;
        margin-top: 40px;

        @include mobile {
          width: 100%;
          margin-top: 10px;
        }

        &-control {
          margin-top: 20px;
        }

        .submit {
          margin-top: 50px;
          padding-bottom: 20px;
        }
      }
    }
  }

  .back {
    margin-bottom: 10px;
  }

  .business-info {
    min-height: fit-content;
    max-height: 320px;
    overflow: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    .document-btn {
      background-color: #43d882;
      width: auto;
      border-radius: 5px;
      color: #ffffff;
      padding: 10px 12px;
      text-decoration: none;
      font-weight: 600;
      font-size: 13px;
    }

    p {
      font-size: 14px;
      color: '#0000008A';
    }
  }

  .business-info::-webkit-scrollbar {
    display: none;
  }
}
