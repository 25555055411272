$base: #003895;
$black_color: #000000;
$blackColorSecondary: rgba(0, 0, 0, 0.87);
$white_color: #ffffff;
$primaryLight: #003895;
$primary: #003895;
$primaryDark: #2a2d56;

$secondaryColor: #ffc40e;
$buttonColor: #43d882;
$buttonColorDark: rgb(50, 200, 130);
$color-gray: #505558;

$lightGrey: #dadce0;
$darkGrey: #242526;
$labelColor: #7b7b7b;
$borderColor: #eeeff1;
$colorGray: #505558;
$colorGray2: #586274;

$statusSuccess: #98fc94;
$statusFailed: #ff9a9e;

$error: #ff0000;

$phoneMaxSize: 600;
$desktopMinSize: 993;

$phoneMaxWidth: $phoneMaxSize * 1px;
$desktopMinWidth: $desktopMinSize * 1px;

$albumArtSize: 150;
$desktopGrid: 800;

$drawerWidth: 230px;

$coverSize: 74px;
$coverSizeLarge: 80px;
$coverMaximizedSize: $coverSize - 20;

@mixin mobile {
  @media (max-width: $phoneMaxWidth) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktopMinWidth) {
    @content;
  }
}
