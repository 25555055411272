.book-imports {
  padding-top: 20px;

  .paper {
    padding: 20px 30px;
    margin-bottom: 30px;

    @include mobile {
      padding: 20px 15px;
    }
  }

  .title {
    color: $primary;
    font-size: 20px;
    font-weight: 700;
  }

  .form {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-top: 10px;

    @include mobile {
      display: block;
    }

    .right {
      padding: 30px;
      flex: 0 0 50%;
      margin-left: 10px;

      @include mobile {
        padding: 0px;
        margin: 0px;
      }
    }

    .left {
      padding: 10px 30px;
      flex: 0 0 50%;
      margin-right: 10px;

      @include mobile {
        padding: 0px;
        flex: 0 0 100%;
      }

      .items-container {
        padding: 10px;
        // height: 450px;
        overflow: scroll;
      }
    }

    .form-control {
      margin-bottom: 15px;
    }

    .header {
      color: $primary;
      margin-bottom: 20px;
    }
  }
}
