.shipments {
  text-align: center;

  .amount {
    font-weight: bolder;
    color: $buttonColor;
  }

  .status {
    padding: 6px;
    background-color: #f7df8e;
    text-align: center;
    font-weight: bolder;
  }

  .Delivered,
  .Paid,
  .Export {
    background-color: $statusSuccess;
  }

  .ClarificationNeeded,
  .Import {
    background-color: $secondaryColor;
  }

  .DeliveryFailed,
  .PickupFailed,
  .Cancelled {
    background-color: $statusFailed;
  }

  .trackingId {
    font-weight: bolder;
  }

  .more-info {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
  }

  .item-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }

  .paper-info {
    padding: 10px;
    margin: 0px 5px;
    text-align: center;

    .title {
      font-weight: 700;
    }

    .description {
      font-weight: 300;
    }
  }

  .filter-wrap {
    width: 50%;

    .filter-item {
      margin-right: 5px;
      margin-bottom: 5px;
    }

    @include respond(1052px) {
      width: 45%;
    }
    @include respond(1025px) {
      width: 100%;
    }
  }
  .file-upload-button {
    border: none;
    outline: none;
    border-radius: 4px;
    padding: 8px 15px;
    background: $primary;
    width: fit-content;
    color: #fff;
    font-size: 12px;
    text-decoration: none;
    cursor: pointer;
  }
}
