.users {
  text-align: center;

  .add-btn-container {
    text-align: start;
    margin-bottom: 20px;
  }

  .wallet {
    font-weight: bolder;
    color: $buttonColor;
  }

  .userId {
    font-weight: bolder;
  }
}
