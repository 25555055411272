.book-shipment {
  padding-top: 20px;

  .paper {
    padding: 20px 30px;
    margin-bottom: 30px;

    @include mobile {
      padding: 20px 15px;
    }
  }

  .title {
    color: $primary;
    font-size: 20px;
    font-weight: 700;
  }

  .form {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-top: 10px;

    @include mobile {
      display: block;
    }

    .right {
      width: 45%;
      padding: 30px;
      // flex: 0 0 50%;
      margin-left: 10px;

      @include mobile {
        padding: 0px;
        margin: 0px;
      }
    }

    .left {
      width: 55%;
      padding: 10px 30px;
      overflow-x: auto;
      // flex: 0 0 50%;
      margin-right: 10px;

      @include mobile {
        padding: 0px;
        flex: 0 0 100%;
      }

      .receivers-container {
        padding: 10px;
        // height: 680px;
        // overflow: scroll;
        margin-bottom: 30px;
      }

      .items-container {
        padding: 10px;
        // height: 450px;
        overflow: scroll;
      }
    }

    .form-control {
      margin-bottom: 15px;
    }

    .header {
      color: $primary;
      margin-bottom: 20px;
    }
  }

  .red {
    font-size: 16px;
    color: red;
    text-decoration: line-through;
    font-weight: bolder;
  }

  .green {
    font-size: 16px;
    color: #06a77d;
    font-weight: bolder;
  }

  .delete-receiver {
    margin-bottom: 20px;
    cursor: pointer;
    color: $error;
    font-size: 12px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    div {
      padding: 8px;
      border-radius: 8px;
      background-color: $borderColor;
      display: flex;
      align-items: center;

      img {
        margin-right: 10px;
      }
    }
  }
  .receiver-item-wrap {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    background-color: $borderColor;
    padding: 5px 6px;
    border-radius: 8px;
    transition: all 0.3s ease;
    button {
      border: none;
      outline: none;
      border-radius: 8px;
      padding: 10px 0;
      background: transparent;
      width: 46%;
      color: $darkGrey;
      font-size: 14px;
      cursor: pointer;
      &.active {
        background: white;
      }
    }
  }
}
