.login {
  height: 100vh;
  background-image: url('/static/images/jpg/login-background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
  overflow-y: hidden;

  .overlay {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 3;
      background: rgba(0, 0, 0, 0.3) 0% 0% no-repeat padding-box;
      mix-blend-mode: multiply;
    }
  }

  .content {
    z-index: 10;
    height: inherit;
    width: 100%;

    .logo {
      padding: 40px 40px 0px 40px;
      @include mobile {
        padding: 20px 20px 0px 20px;
      }

      img {
        width: 120px;
      }
    }

    .surface {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      height: 100%;

      @include mobile {
        justify-content: center;
      }

      &-content {
        padding-top: 5%;
        padding-right: 5%;
        padding-bottom: 5%;
        height: 100%;
        width: 50%;

        @include mobile {
          width: 100%;
          padding: 20px;
        }

        .login-box {
          float: right;
          margin-top: 10px;
          padding: 30px;
          height: 80%;
          width: 450px;
          text-transform: uppercase;

          @include mobile {
            width: 100%;
          }

          .form {
            margin-top: 90px;

            .form-control {
              margin-top: 20px;
            }

            .form-label {
              font-weight: 700;
            }
          }
        }

        .submit {
          margin-top: 70px;
        }
      }
    }
  }
}
