.empty-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;

  & > div {
    text-align: center;
    width: 100%;
    align-self: center;

    & p {
      line-height: 2rem;
    }
  }

  &__heading {
    color: #0f1414;
    font-weight: 800;
    font-size: 1.5rem;
    padding-top: 2em;
    @include respond(mini-mobile) {
      font-size: 1.2rem;
    }
  }

  &__sub-heading {
    color: #545859;
    font-size: 1.5rem;
    font-weight: 500;
    @include respond(mini-mobile) {
      font-size: 1.8rem;
    }
  }
}
