.input-group--wrapper {
  margin-bottom: auto !important;
  margin-top: auto !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: 1rem !important;
}
.mt-2 {
  margin-top: 2rem !important;
}
.mt-3 {
  margin-top: 3rem !important;
}
.mt-4 {
  margin-top: 4rem !important;
}
.mt-5 {
  margin-top: 5rem !important;
}
.mt-6 {
  margin-top: 6rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}
.mb-2 {
  margin-bottom: 2rem !important;
}
.mb-3 {
  margin-bottom: 3rem !important;
}
.mb-4 {
  margin-bottom: 4rem !important;
}
.mb-5 {
  margin-bottom: 5rem !important;
}
.mb-6 {
  margin-bottom: 6rem !important;
}

button.loading {
  height: 2.5rem;
  width: 2.5rem;
  padding: 0;
  border-radius: 50%;
  align-self: center;
  background-color: transparent;
  border: 0.6rem solid rgb(220, 220, 220);
  border-top: 0.6rem solid $buttonColor;
  animation: spin 1s 0.3s infinite;
  margin-top: 1rem;
  margin: auto;
  display: block;

  &:hover {
    background-color: transparent;
  }

  & span {
    display: none;
  }
}

.disable-element {
  pointer-events: none;
  opacity: 0.7;
}
