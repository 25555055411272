body,
.root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 0px;
  min-height: 100%;
  overflow: scroll !important;
  position: relative;
  width: 100vw;
}

// #player {
// visibility: hidden;
// }

a {
  text-decoration: none;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.clear-float {
  clear: both;
}

.button-wrapper {
  margin: 20px 0px;
  text-align: center;
}

.align-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.align-left-center {
  align-items: center;
  display: flex;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.form-wrapper {
  text-align: center;
}

.center-align {
  text-align: center;
}

.spinner {
  height: 100%;
  width: 100%;
}

//TODO: fix other use-cases
.desktop {
  .copyright {
    margin-bottom: 96px;
  }
}

a {
  &:link,
  &:visited {
    color: inherit;
  }

  &:hover {
    text-decoration: underline;
  }
}

small {
  margin-top: 5px;
  color: red;
}

.submit-btn {
  background-color: $buttonColor !important;
  color: white !important;
  padding: 5px 30px !important;
}

.insert-form {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  cursor: pointer;

  & span {
    color: $primary;
    font-weight: 600;
    margin-left: 1rem;
    font-size: 16px;
    padding: 0;
    align-self: center;
  }
}

.delete-item {
  display: flex;
  align-items: center;
  float: right;
  margin-top: 1rem;
  cursor: pointer;

  & span {
    font-size: 16px;
    margin-left: 1rem;
    font-weight: 600;
    color: #ef476f;
  }
}

.tracking {
  margin-bottom: 5px;
  display: flex;

  .tracking-number {
    background-color: #5f5d5d;
    font-weight: bolder;
    flex: 0 0 5%;
    padding: 10px 30px;
  }

  .tracking-date {
    flex: 0 0 20%;
    padding: 10px 10px;
  }

  .tracking-location {
    flex: 0 0 20%;
    padding: 10px 10px;
  }

  .tracking-message {
    flex: 0 0 55%;
    padding: 10px 10px;
  }
}

.booking-paper {
  text-align: center;
  cursor: pointer;

  &:hover {
    border-color: #103a90 !important;
    box-shadow: 0px 2px 1px -1px rgb(16, 58, 144),
      0px 1px 1px 0px rgb(16, 58, 144), 0px 1px 3px 0px rgb(16, 58, 144);
  }
}
