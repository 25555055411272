.new-tracking {
  &__modal {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    top: 10%;
    padding-bottom: 50px;
    z-index: 1050;
    display: none;
    overflow: scroll;
    outline: 0;
    min-height: fit-content;
    max-height: 500px;
    width: 36.7vw;
    background-color: #fff;
    animation: fadeOut 0.5s;
    text-align: left;
    &.show {
      animation: fadeIn 0.5s;
      display: block;
    }

    @include respond(1280px) {
      width: 50vw;
    }
    @include respond(smaller) {
      width: 90vw;
      top: 10%;
      padding-bottom: 100px;
    }
    &__wrap {
      display: flex;
      flex-direction: column;
    }
    &__header {
      padding: 20px;
      display: flex;
      justify-content: flex-start;
      gap: 5%;
      align-items: center;
      border-bottom: 1px solid #eef1f8;
      h1 {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        color: #0f1414;
      }
      button {
        transform: scale(2);
        color: #22428f !important;
      }
    }
    &__body {
      &__wrap {
        padding: 24px 40px 0 40px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        @include respond(mini-mobile) {
          padding: 24px 24px 0 24px;
        }
      }
    }
    &__input {
      label {
        font-style: normal;
        font-weight: 400 !important;
        font-size: 18px !important;
        line-height: 24px !important;
        color: #0f1414;
        margin-bottom: 24px !important;
      }
    }
    &__success {
      animation: fadeIn 0.5s;
      &__body {
        &__wrap {
          display: flex;
          flex-direction: column;
          gap: 20px;
        }
      }
      &__items {
        padding: 40px 40px 0 40px;
        display: flex;
        flex-direction: column;

        @include respond(smaller) {
          padding: 34px 34px 0 34px;
        }
      }
      &__item {
        padding: 0 0 38px 24px;
        border-left: 1px dashed #b7b7b7;
        position: relative;
        &__wrap {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 2px;
          &::before {
            // content: '';
            display: block;
            width: 14px;
            height: 14px;
            background: #22428f;
            position: absolute;
            left: -7px;
            border-radius: 1em;
          }

          .status-icon {
            position: absolute;
            left: -12px;
            top: 0;
          }

          .see-more {
            height: 24px;
            width: 24px;
            background: #22428f;
            border-radius: 50px;
            position: absolute;
            left: -12px;
          }
        }
        &__status h3 {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: #0f1414;
        }
        &__date span {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #545859;
        }
        &__expand-more button {
          font-style: normal;
          font-weight: 400 !important;
          font-size: 16px !important;
          line-height: 22px !important;
          color: #22428f !important;
          text-transform: none !important;
          padding: 0 !important;
          margin-top: 2px;
        }
        &:last-child {
          border-left: none;
        }
        &.draft {
          .new-tracking__modal__success__item__wrap::before {
            background: #545859;
          }
        }
        &.pendingconfirmation,
        &.awaitingpickup,
        &.assignedfordelivery,
        &.awaitingdropoff,
        &.shipmentprocessing,
        &.riderassigned,
        &.awaitingdocumentupload,
        &.deliveryinprogress {
          .new-tracking__modal__success__item__wrap::before {
            background: #efa516;
          }
        }
        &.confirmed,
        &.delivered,
        &.documentuploaded,
        &.pickupdispatched,
        &.successfullypicked,
        &.pickuprescheduledbycourier {
          .new-tracking__modal__success__item__wrap::before {
            background: #28a745;
          }
        }
        &.cancelled,
        &.pickupfailed,
        &.pickupcancelled,
        &.pickupcancelledbycourier,
        &.pickupcancelledbymerchant,
        &.pickupcancelledinternally,
        &.deliveryfailed,
        &.clarificationneeded {
          .new-tracking__modal__success__item__wrap::before {
            background: #dc3545;
          }
        }
      }
      &__info {
        padding: 0 40px 0 40px;
        // border-bottom: 1px solid #eef1f8;
        &__wrap {
          display: flex;
          align-items: center;
          justify-content: space-between;
          // padding: 24px 0 24px 0;
        }
        &__content {
          display: flex;
          flex-direction: column;
          gap: 8px;
        }
        &__label h4 {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          margin: 0;
          color: #545859;
        }
        &__value span {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: #0f1414;
        }
        &__cta button {
          font-weight: 400 !important;
          font-size: 18px !important;
          line-height: 24px !important;
          color: #22428f !important;
          text-transform: capitalize !important;
        }
      }
    }
    &__backdrop {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1040;
      background-color: #000;
      display: none;
      &.fade {
        opacity: 0;
        transition: opacity 0.15s linear;
      }
      &.fade.show {
        display: block;
        opacity: 0.5;
      }
    }
  }
}
