.inputGroup2 {
  display: flex;
  align-items: center;
  justify-content: center;

  &--double {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1rem;
    justify-content: stretch;
    @include mobile {
      grid-template-columns: 1fr;
    }
  }

  &__inputs {
    width: 100%;
    display: flex;
    flex-direction: column;

    & > label {
      font-size: 13px;
      font-weight: 400;
      color: $labelColor;
    }

    &__input {
      font-size: 1.3rem;
      padding: 1rem 0;
      border: none;
      border-bottom: 1px solid $darkGrey;
      transition: all 0.3s;

      @include mobile {
        font-size: 16px;
      }

      &--double {
        padding: 1rem;
      }
      &:focus {
        outline: none;
        border-bottom: 1px solid $primary;
      }
    }
  }
}

.google-places-container {
  position: relative;
}

.google-auto-complete {
  border: none !important;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid $darkGrey !important;
  transition: all 0.3s;
  padding-top: 10px;
  padding-left: 25px;
  padding-bottom: 5px;

  &:focus {
    outline: none !important;
    border-bottom: 2px solid $primary !important;
  }

  @include mobile {
    font-size: 16px !important;
  }
}

.google-places-icon {
  margin: 0px;
  position: absolute;
  left: 0;
  bottom: 0;
  padding-bottom: 5px;
}
