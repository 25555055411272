@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideInLeft {
  0% {
    right: -50rem;
  }
  100% {
    right: 0;
  }
}

@keyframes slideInLeft2 {
  0% {
    transform: translateX(20rem);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes zoomInOut {
  0% {
    opacity: 0;
    transform: scale(0.2);
  }
  60% {
    transform: scale(1.08);
  }
  100% {
    transform: scale(1);
  }
}
