@import './variables';
@import './global';
@import './partials/style';
@import './typography';
@import './utilities';
@import './animation';
@import './mixins';
@import './new-tracking';

@import './inputs/inputGroup2';

@import '../components/SubmitButton/css/submit-button';
@import '../components/BusyOverlay/css/busy-overlay';
@import '../components/CustomSpinner/css/custom-spinner';
@import '../components/EmptyList/css/empty-list';
@import '../components/RequestOption/css/request-option';

@import './layout/new-shipping-steps-layout';

@import '../pages/book/css/style';
@import '../pages/dashboard/css/style';
@import '../pages/book-shopnship/css/style.scss';
@import '../pages/dashboard/components/booking/css/style';
@import '../pages/dashboard/components/deadletters/css/style';
@import '../pages/dashboard/components/feedback/css/style';
@import '../pages/dashboard/components/pickups/css/style';
@import '../pages/dashboard/components/settings/css/style';
@import '../pages/dashboard/components/shipments/css/style';
@import '../pages/dashboard/components/shopnships/css/style';
@import '../pages/dashboard/components/transactions/css/style';
@import '../pages/dashboard/components/users/css/style';
@import '../pages/dashboard/components/withdrawalRequests/css/style';
@import '../pages/imports/css/style';
@import '../pages/login/css/style';
@import '../pages/user-information/css/style';

@media only screen and (max-width: $phoneMaxWidth) {
  /* For small: */
  @import './layout/small';
}

@media only screen and (min-width: $phoneMaxWidth) {
  /* For large: */
  @import './layout/large';
}
