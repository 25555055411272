.deadletters {
  .Credit,
  .Deposit,
  .Reversal {
    color: $buttonColor;
  }

  .status {
    padding: 6px;
    background-color: #f7df8e;
    text-align: center;
    font-weight: bolder;
  }

  .Success {
    background-color: $statusSuccess;
  }

  .ClarificationNeeded {
    background-color: $secondaryColor;
  }

  .Failed {
    background-color: $statusFailed;
  }

  .trackingId {
    font-weight: bolder;
  }
}
