.settings {
  .title {
    font-size: 20px;
  }

  .body-title {
    margin: 20px 0px 10px 0px;
    font-size: 16px;
  }

  .paper {
    padding: 10px;
  }

  .location-settings {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;

    @include mobile {
      display: block;
    }
  }

  .paper-info {
    flex: 0 1 32%;
    padding: 10px;

    @include mobile {
      margin-bottom: 15px;
    }
  }

  .form-control {
    margin-top: 20px;
  }

  .configs {
    margin-top: 35px;
  }

  .config-settings {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;

    @include mobile {
      display: block;
    }

    &-content {
      flex: 0 1 50%;
    }

    &-coupon {
      flex: 0 1 49%;
    }
  }
}