.booking {
  text-align: center;
  padding: 40px;

  @include mobile {
    padding: 20px;
  }

  &-image {
    width: 100px;

    @include mobile {
      width: 70px;
    }
  }

  .title {
    font-weight: 700;
    font-size: 25px;
    margin-top: 20px;
    line-height: 1.2em;

    @include mobile {
      font-size: 16px;
    }
  }

  .form {
    margin-top: 60px;
    text-align: center;

    @include mobile {
      margin-top: 40px;
    }

    .form-content {
      width: 35%;

      @include mobile {
        width: 100%;
      }
    }

    .submit {
      display: flex;
      justify-content: center;
      margin-top: 90px;
    }
  }
}
