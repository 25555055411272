.new-shipping-steps-layout {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  animation: fadeIn 0.5s;

  &__wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  &__header {
    &__wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 29px 17.4%;
      @include respond(smaller) {
        padding: 29px 8.7%;
      }
      @include respond(mini-mobile) {
        padding: 20px 24px;
      }
    }
    h1 {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      color: #0f1414;
    }
    button {
      transform: scale(2);
      padding: 4px;
      color: #22428f !important;
    }
    border-bottom: 1px solid #eef1f8;
    @include respond(mini-mobile) {
      border-bottom: unset;
    }
  }
  &__body {
    height: 100%;
    overflow: auto;
    &__wrap {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: unset;
      padding: 0 22.09% 0 17.4%;
      gap: 81px;
      @include respond(1400px) {
        padding: 0 11.045%;
      }
      @include respond(smaller) {
        flex-direction: column;
        gap: 0px;
      }
      @include respond(mini-mobile) {
        padding: 0 16px;
      }
    }
    &__label {
      display: flex;
      flex-direction: column;
      gap: 18px;
      padding: 60px 7.7% 0 0;
      height: 100%;
      border-right: 1px solid #eef1f8;
      @include respond(smaller) {
        height: unset;
        flex-direction: row;
        border-right: none;
        justify-content: space-between;
        padding: 0px 8px;
        width: 100%;
      }
      span {
        font-style: normal;
        font-size: 16px;
        line-height: 160%;
        padding: 10px;
        font-weight: 400;
        color: #545859;
        cursor: pointer;
        &.completed {
          color: #0f1414;
          font-weight: 500;
          display: flex;
          align-items: center;
          gap: 6px;
        }
        &.active {
          font-weight: 500;
          color: #22428f;
          background: #f8fbff;
          border-radius: 8px;
        }
        @include respond(smaller) {
          font-size: 0px;
          padding: 10px 0;
          .two-icons {
            display: flex;
            align-items: center;
            gap: 7px;
          }
          svg {
            transform: scale(1.1);
          }
          .check {
            display: none;
          }
          &.completed {
            svg path {
              fill: #22428f !important;
            }
          }
        }
      }
    }
    &__progress {
      display: none;
      @include respond(smaller) {
        display: block;
        width: 100%;
        padding: 0 8px;
        div {
          border-radius: 8px;
        }
      }
    }
    &__content {
      height: 100%;
      padding-top: 60px;
      flex: 2;
      width: 100%;
      @include respond(mini-mobile) {
        padding-top: 24px;
      }
    }
  }
}
