.shipments {
  text-align: center;

  .amount {
    font-weight: bolder;
    color: $buttonColor;
  }

  .status {
    padding: 6px;
    background-color: #f7df8e;
    text-align: center;
    font-weight: bolder;

    &.tickets {
      &.Overdue {
        background-color: $statusFailed;
      }
      &.Resolved {
        background-color: $statusSuccess;
      }
    }
  }

  .Delivered,
  .Paid {
    background-color: $statusSuccess;
  }

  .ClarificationNeeded {
    background-color: $secondaryColor;
  }

  .DeliveryFailed,
  .Cancelled {
    background-color: $statusFailed;
  }

  .trackingId {
    font-weight: bolder;
  }

  .more-info {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
  }

  .item-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }

  .paper-info {
    padding: 10px;
    margin: 0px 5px;
    text-align: center;

    .title {
      font-weight: 700;
    }

    .description {
      font-weight: 300;
    }
  }
}
