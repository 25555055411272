.new-shop-n-ship {
  font-family: 'General Sans', sans-serif;

  &__prompt {
    animation: fadeIn 1s;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1071;
    margin-top: 105px;
    display: none;
    overflow: scroll;
    outline: 0;
    height: fit-content;
    width: 450px;
    background-color: #fff;
    box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    @include respond(mini-mobile) {
      width: 95%;
    }
    &.fade {
      opacity: 0;
      transition: opacity 0.15s linear;
    }
    &.fade.show {
      opacity: 1;
      display: block;
    }
    &__wrap {
      display: flex;
      flex-direction: column;
    }
    &__header {
      height: 66px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 12px 24px;
      h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #003895;
      }
    }
    &__body {
      background: #f8fbff;
      box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
      &__wrap {
        display: flex;
        flex-direction: column;
        padding: 25.5px 24px;
        gap: 16px;
        @include respond(mini-mobile) {
          padding: 16px;
        }
      }
      &__content p {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 160%;
        color: #0f1414;
      }
      &__options {
        &__wrap {
          display: flex;
          flex-direction: column;
          gap: 13px;
        }
        &__header span {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 160%;
          color: #003895;
        }
        &__list {
          display: flex;
          flex-direction: column;
          gap: 8px;
          &__item {
            background: #ffffff;
            border: 1px solid #e2e4e8;
            box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
            border-radius: 8px;
            transition: all 0.3s ease;
            cursor: pointer;
            &.active {
              box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.12),
                0px 0px 0px 3px #d4e5ff;
              border: 2px solid #22428f;
              .new-shop-n-ship__prompt__body__options__list__item {
                &__name h3 {
                  color: #22428f;
                }
              }
            }
            &__wrap {
              padding: 24px;
              display: flex;
              flex-direction: column;
              gap: 7px;
              @include respond(mini-mobile) {
                padding: 16px;
              }
            }
            &__name h3 {
              font-weight: 600;
              font-size: 14px;
              line-height: 19px;
              text-transform: uppercase;
              color: #003895;
            }
            &__address span {
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
              color: #545859;
            }
          }
        }
      }
    }
    &__footer {
      &__wrap {
        padding: 20px 24px;
        @include respond(mini-mobile) {
          padding: 16px;
        }
      }
      &__actions {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        gap: 8px;
        button {
          box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
          border-radius: 4px;
          border: 0;
          padding: 9px 24px;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 140%;
          &:disabled {
            opacity: 0.4;
          }
          &:first-child {
            background: #ffffff;
            border: 1px solid #e2e4e8;
            color: #22428f;
          }
          &:last-child {
            background-color: #22428f;
            color: #ffffff;
            &:disabled {
              opacity: 0.4;
              background: #b7b7b7;
            }
          }
        }
      }
    }
    &__backdrop {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1040;
      background-color: #000;
      display: none;
      &.fade {
        opacity: 0;
        transition: opacity 0.15s linear;
      }
      &.fade.show {
        display: block;
        opacity: 0.5;
      }
    }
  }
  &__modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: scroll;
    outline: 0;
    height: 100vh;
    width: 100vw;
    background-color: #fff;
    animation: fadeOut 0.5s;
    &.show {
      animation: fadeIn 0.5s;
      display: block;
    }
    &__wrap {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    &__header {
      &__wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 29px 17.4%;
        @include respond(mini-mobile) {
          padding: 20px 24px;
        }
      }
      h1 {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        color: #0f1414;
      }
      button {
        transform: scale(2);
        color: #22428f !important;
      }
      border-bottom: 1px solid #eef1f8;
      @include respond(mini-mobile) {
        border-bottom: unset;
      }
    }
    &__body {
      height: 100%;
      &__wrap {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: unset;

        @include respond(mini-mobile) {
          padding: 0 10px;
        }
      }

      &__content {
        height: 100%;
        // padding-top: 60px;
        flex: 2;
        @include respond(mini-mobile) {
          padding-top: 24px;
        }
      }
      &__form {
        h3 {
          color: #003895;
        }
        label {
          font-weight: 400 !important;
          font-size: 16px !important;
          line-height: 20px !important;
          margin-bottom: 4px !important;
          white-space: nowrap !important;
        }
        textarea {
          font-size: 15px !important;

          line-height: 20px;
        }
        &__item {
          display: flex;
          flex-direction: column;

          &__input {
            transition: all 0.4s ease !important;
          }
          &__buttons {
            display: flex;
            align-items: center;
            gap: 16px;
            margin: 64px 0 100px 0 !important;
          }
          &__button {
            width: 100%;
            background: #ffffff;
            border: 1px solid #e2e4e8;
            box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
            border-radius: 8px;
            padding: 14px 24px;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 140%;
            color: #22428f;
            text-align: center;
            cursor: pointer;
          }
        }
      }

      #input-control {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        padding: 10px 0;

        label {
          span {
            font-weight: 500 !important;
            font-size: 16px !important;
            color: #003895;

            line-height: 20px !important;
            margin-bottom: 4px !important;
            padding-left: 0px;
          }
        }

        .radio-group {
          display: flex;
          flex-direction: column;
        }

        .radio-group-option {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin: 15px 0px 0px 0px !important;
          cursor: pointer;

          span {
            margin-left: 10px;
            font-size: 14px;
            line-height: 20px;
          }
        }

        input[type='radio'] {
          cursor: pointer;
        }

        input[type='file'] {
          margin-top: 10px;
        }

        small {
          display: block;
          color: #d32f2f;
          margin: 5px;
        }

        #upload-button {
          margin-top: 10px;

          .filename {
            margin-top: 10px;
            font-size: 1rem;
            display: block;
          }
        }
      }
      .agreement {
        display: flex;
        align-items: center;
        padding: 10px 0px;
        gap: 8px;
        font-weight: 500 !important;
        input {
          cursor: pointer;
          transform: scale(1.5);
        }
        span {
          padding-left: 8px;
          font-size: 14px;
        }
      }
    }
  }

  &__payment-successful {
    @include dashboardWrapper;
    text-align: center;
    margin-top: 10rem;

    & h1 {
      color: var(--color-blue-dark);
      font-weight: 800;
      font-size: calc(1.8rem + 1vw);
      margin-top: 4rem;
    }

    & p {
      width: 40rem;
      margin: auto;
      color: #3d5276;
      font-size: 1.5rem;
      font-weight: 500;
      align-self: center;
    }
  }

  .items-container {
    padding: 20px;
    margin-bottom: 70px;
  }

  .delete-item {
    font-size: 12px;
    margin-left: 1rem;
    font-weight: 600;
    color: #ef476f;
  }
}

$desktop_average: 1350px;

.order-summary {
  font-family: 'General Sans', sans-serif;

  &__wrap {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  &__header {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #1d1d1d;
    margin-bottom: 6px;
  }
  &__shipment {
    background: #ffffff;
    border: 1px solid #e2e4e8;
    box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    &__wrap {
      padding: 24px;
      @include respond(mini-mobile) {
        padding: 16px;
      }
    }
    &__addresses {
      display: flex;
      flex-direction: column;
      padding: 24px 24px 24px 9px;
      border-bottom: 1px solid #eeeeee;
      @include respond(mini-mobile) {
        padding: 16px 16px 16px 9px;
      }
    }
    &__address {
      position: relative;
      padding-left: 15px;
      border-left: 1px dashed #b7b7b7;
      padding-bottom: 66px;
      &.multiple {
        padding-bottom: 40px;
      }
      &.final {
        padding-bottom: 0;
      }
      &::before {
        content: '';
        position: absolute;
        display: block;
        width: 14px;
        height: 14px;
        border: 1px solid #ffffff;
        transform: translate(-155%, -3px);
        background: #14cc3c;
      }
      &:first-child {
        @include respond($desktop_average) {
          padding-bottom: 70px;
        }
        &::before {
          background: #22428f;
          border-radius: 50%;
        }
      }
      &:last-child {
        border-left: none;
      }
      &__wrap {
        display: flex;
        justify-content: space-between;
        margin-top: -20px;
        // align-items: flex-start;

        @include respond($desktop_average) {
          flex-direction: column;
          gap: 16px;
          align-items: flex-start;
        }
      }
      &__content {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      &__name {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #545859;
        margin-bottom: 4px;
      }
      &__location {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #0f1414;
        margin-bottom: 4px;
      }
      &__meta {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #545859;
      }
      &__items {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: #545859;
      }
      &__options {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: #0f1414;
        margin-bottom: 4px;
      }
      &__action {
        button {
          background: #eef1f8;
          border-radius: 46px;
          padding: 8px 10px !important;
          gap: 6px;
          &:disabled {
            opacity: 0.4;
          }
          span {
            font-style: normal !important;
            font-weight: 400 !important;
            font-size: 14px !important;
            line-height: 19px !important;
            color: #22428f !important;
            text-transform: none !important;
          }
          svg path {
            fill: #22428f;
          }
        }
      }
    }
    &__meta {
      padding: 16px 0;
      &:not(:last-child) {
        border-bottom: 1px solid #eeeeee;
      }
      &__wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include respond(medium) {
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;
        }
      }
      &__content {
        display: flex;
        flex-direction: column;
        gap: 4px;

        &__value {
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          // color: #0f1414;
        }
      }
      &__action {
        button {
          background: #eef1f8;
          border-radius: 46px;
          padding: 8px 10px !important;
          gap: 6px;
          &:disabled {
            opacity: 0.4;
          }
          span {
            font-style: normal !important;
            font-weight: 400 !important;
            font-size: 14px !important;
            line-height: 19px !important;
            color: #22428f !important;
            text-transform: none !important;
          }
          svg path {
            fill: #22428f;
          }
        }
      }
    }
  }
  &__items {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  &__item {
    background: #ffffff;
    border: 1px solid #e2e4e8;
    box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    &__wrap {
      display: flex;
      flex-direction: column;
      padding: 24px;
    }
    &__actions {
      align-self: flex-end;
      display: flex;
      align-items: center;
      gap: 16px;
      button {
        padding: 8px 10px !important;
        gap: 6px;
        &:disabled {
          opacity: 0.4;
        }
        span {
          font-style: normal !important;
          font-weight: 400 !important;
          font-size: 14px !important;
          line-height: 19px !important;
          text-transform: none !important;
        }
      }
      button:first-child {
        border-radius: 36px;
        background: rgba(240, 93, 61, 0.05);
        span {
          color: #f05d3d !important;
        }
        svg path {
          fill: #f05d3d;
        }
      }
      button:last-child {
        border-radius: 46px;
        background: #eef1f8;
        span {
          color: #22428f !important;
        }
        svg path {
          fill: #22428f;
        }
      }
    }
    &__rows {
      display: flex;
      flex-direction: column;
    }
    &__row {
      &:not(:last-child) {
        border-bottom: 1px solid #eeeeee;
      }
      &__wrap {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 16px 0;
        @include respond(tablet) {
          flex-direction: column;
          gap: 16px;
        }
        &:not(:nth-child(2)) {
          padding: 16px 22% 16px 0;
        }
      }
      &__content {
        display: flex;
        flex-direction: column;
        gap: 4px;

        &__value {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          color: #0f1414;
        }
      }
    }
  }
  &__coupon {
    background: #ffffff;
    border: 1px solid #e2e4e8;
    box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    &__wrap {
      padding: 24px;
      @include respond(mini-mobile) {
        padding: 16px;
      }
    }
    .MuiInputBase-root.MuiInput-root {
      border: 0 !important;
      box-shadow: none !important;
    }
  }
  &__additional-notes {
    background: #ffffff;
    border: 1px solid #e2e4e8;
    box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    &__wrap {
      display: flex;
      justify-content: space-between;
      gap: 8px;
      padding: 24px;
      @include respond($desktop_average) {
        flex-direction: column;
      }
    }
    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #0f1414;
    }
    span:not(.button-span) {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #545859;
      margin-bottom: 4px;
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    gap: 16px;
    margin: 35px 0;
    @include respond(mini-mobile) {
      flex-direction: column-reverse;
    }
    &__action {
      width: 100%;
      background: #ffffff;
      border: 1px solid #e2e4e8;
      box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      padding: 14px 24px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 140%;
      color: #22428f;
      text-align: center;
      cursor: pointer;
    }
  }
}

.quote-summary {
  font-family: 'General Sans', sans-serif;

  .item-label {
    color: #545859;
    margin-bottom: 15px;
  }

  @include dashboardWrapper;
  margin-top: 3rem;
  padding-bottom: 3rem;

  &__card {
    font-family: 'General Sans', sans-serif;
    padding: 3rem 2.5rem;
    background-color: #fafbfd;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
    border-radius: 0.7rem;

    &__top {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      &__route {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        --color-text: #3d5276;
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 15px;

        .location-content {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: flex-start;
        }

        .location-content:not(:last-child) {
          width: 25%;
          flex: 1;
        }

        .location-content:last-child {
          margin-left: 15px;
        }

        .location-content:nth-child(1) {
          border-right: 1px solid #e2e4e8;
        }

        .location-content:nth-child(2) {
          margin-left: 15px;
        }

        .location {
          width: 100%;
          height: 60px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        .location-details {
          display: flex;
        }

        .location-image {
          margin-right: 1rem;
        }

        .edit-quote {
          height: 60px;
          color: #22428f;
          text-decoration: underline;
          cursor: pointer;
        }

        @include respond(tablet) {
          font-size: 1.5rem;
          font-weight: 600;
        }

        &:nth-child(2) {
          margin: 0 0.7rem;
          display: inline-block;
        }
      }

      &__cost {
        color: var(--color-blue-dark);
        font-weight: 700;
        margin: 1rem 0;
      }

      &__weight {
        width: 100%;
        font-size: 1.7rem;
        font-weight: 600;
        --color-text: #3d5276;
        color: var(--color-text);
        margin-top: 3rem;
      }
    }

    &__bottom {
      margin-top: 3rem;
      & p {
        font-weight: 500;
        text-align: center;
        width: 40rem;
        margin: auto;
        color: hsl(346, 84%, 61%);
        font-size: 1.5rem;

        @include respond(tablet) {
          font-size: 1.8rem;
          width: 90% !important;
        }
      }
    }
  }

  &__title {
    font-family: 'General Sans', sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--color-blue-dark);
    margin: 4rem 0;

    @include respond(tablet) {
      font-size: 1.7rem;
    }
  }

  &__share {
    display: flex;
    justify-content: space-between;
  }

  &__rates {
    font-family: 'General Sans', sans-serif;
    border: 1px solid #eeeff1;
    margin-bottom: 1rem;
    border-radius: 0.7rem;
    padding: 1.5rem;
    transition: background-color 0.3s;
    cursor: pointer;

    @include respond(tablet) {
      font-size: 10px;
    }

    &--shipment-types {
      & p:nth-child(1) {
        color: #3d5276;
      }
    }

    &--selected {
      border: 2px solid #3d5276;
    }

    &:hover {
      background-color: rgb(247, 246, 246);
    }

    &__top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .rates-select-container {
        width: 60%;
        display: flex;
      }

      & span {
        color: var(--color-gray2);
        font-weight: 700;
        font-size: 1.2rem;
        margin-left: 10px;

        &.selected {
          color: #003896;
        }

        @include respond(tablet) {
          font-size: 1.5em;
        }
      }
      &--summary {
        & h4 {
          max-width: 75%;
        }
        & span {
          color: var(--color-text);
        }
      }
      &--option3 {
        justify-content: flex-start;
        align-items: center;
        & figure {
          display: block;
          margin: 0;
          padding: 0;
        }

        & span {
          margin-left: 1.5rem;
        }
      }
    }

    &__bottom {
      width: 100%;
      margin-left: 10px;
      flex: 1;

      p:first-child {
        color: var(--color-gray2);
        font-weight: 700;
        font-size: 1.1rem;
        max-width: 85%;

        &.selected {
          color: #003896 !important;
        }

        @include respond(tablet) {
          font-size: 1.1em;
        }
      }

      & p:not(:first-child) {
        color: #304466;
        font-size: 1rem;

        @include respond(tablet) {
          font-size: 1.1em;
        }
      }

      & p.selected {
        color: #003896 !important;
      }

      &--shipment-types {
        & p:not(:first-child) {
          width: 80%;
          line-height: 1rem;

          & p.selected {
            color: #003896 !important;
          }
        }
      }

      &__edit {
        display: flex;
        align-items: center;
        color: var(--color-blue-dark);
        font-size: 1.3rem;
        font-weight: 600;
        margin-top: 2rem;

        & span {
          margin-left: 1rem;
        }
      }
    }
  }

  &__apply-bonus {
    font-family: 'General Sans', sans-serif;

    color: var(--color-blue-dark);
    font-size: 1.4rem;
    font-weight: 700;
    margin-top: 1.5rem;
    display: block;
    cursor: pointer;

    & small {
      font-size: 1.1rem;
      color: rgb(5, 58, 5);
      font-weight: 500;
      margin-left: 0.5rem;
    }
  }

  &__terms__checkbox {
    display: flex;
    align-items: flex-start;
    font-size: 1.3rem;
    margin-top: 3rem;

    @include respond(mobile) {
      font-size: 1.25rem;
    }

    & input {
      transform: scale(1.4);
    }

    & label {
      margin: 0;
      margin-left: 1rem;
    }
  }

  &__terms {
    font-family: 'General Sans', sans-serif;

    color: #505558;
    line-height: 1rem;
    font-weight: 500;
    margin-top: 2.5rem;
    text-align: left;
    font-size: 1rem !important;

    a {
      color: #007bff !important;
    }

    @include respond(mobile) {
      font-size: 1.25rem;
    }
  }
}

.sender-info {
  &__checkbox {
    width: fit-content;
    margin: 20px 0 24px 0;
    &.order-summary {
      align-items: flex-start !important;
      span:nth-child(2) {
        font-weight: 600;
        font-size: 16px;
      }
    }
    span:first-child {
      transform: scale(1);
      svg path {
        border: 2px solid #b7b7b7;
        border-radius: 4px;
      }
    }
    span:nth-child(2) {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #545859;
      padding-left: 2px;
    }
  }
}

.delivery-options {
  display: flex;
  flex-direction: column;
  gap: 22px;
  .empty-list {
    min-width: unset;
    width: 100%;
    margin: 1em 10%;
    align-self: center;
    .intro-text {
      min-width: unset;
      width: 100%;
      margin: 1em 0 !important;
      padding-top: 1.5em;
      &__heading {
        padding-top: 1em;
      }
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 10px;
  }
  &__item {
    background: #ffffff;
    border: 1px solid #e2e4e8;
    box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    transition: all 0.3s ease;
    cursor: pointer;
    &.active {
      box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.12), 0px 0px 0px 3px #d4e5ff;
      border: 2px solid #22428f;
      .new-shop-n-ship__prompt__body__options__list__item {
        &__name h3 {
          color: #22428f;
        }
      }
    }
    &__wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px;
      @include respond(mini-mobile) {
        padding: 16px;
      }
    }
    &__name h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      text-transform: uppercase;
      color: #0f1414;
    }
    &__address span {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #545859;
    }
    &__price span {
      padding: 3px 10px;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      background: rgba(16, 197, 78, 0.1);
      border-radius: 16px;
      color: #10c54e;
    }
  }
  &__checkbox {
    display: flex;
    align-items: center;
    gap: 10px;
    width: fit-content;
    margin-left: 1% !important;
    span:first-child svg {
      transform: scale(2);
      path {
        border: 2px solid #b7b7b7;
        border-radius: 4px;
      }
    }
    span:nth-child(2) {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #545859;
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    gap: 16px;
    // margin-bottom: 74px;
    &__action {
      width: 100%;
      background: #ffffff;
      border: 1px solid #e2e4e8;
      box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      padding: 14px 24px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 140%;
      color: #22428f;
      text-align: center;
    }
  }
}

.item-description {
  display: flex;
  flex-direction: column;
  // gap: 16px;

  &__mains {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  &__main {
    margin-top: 30px;
    margin-bottom: 60px;
    border: 1px solid #e2e4e8;
    box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    &__wrap {
      padding: 24px;
      gap: 10px;
      @include respond(mini-mobile) {
        padding: 16px;
      }
    }
    &__action {
      button {
        background-color: rgba(240, 93, 61, 0.05);
        border-radius: 36px;
        justify-content: space-between !important;
        padding: 8px 10px !important;
        gap: 6px;
        &:hover {
          background-color: rgba(240, 93, 61, 0.05) !important;
        }
        span {
          font-style: normal !important;
          font-weight: 400 !important;
          font-size: 14px !important;
          line-height: 19px !important;
          color: #f05d3d !important;
          text-transform: none !important;
        }
        svg path {
          fill: #f05d3d;
        }
      }
    }
    &__grid {
      display: flex;
      flex-direction: column;

      textarea {
        font-size: 15px !important;

        line-height: 18px;
      }

      .flexed-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include respond(mini-mobile) {
          display: block;
        }

        .right-item {
          margin-left: 20px;
          @include respond(mini-mobile) {
            margin-left: 0;
            margin-top: 20px;
          }
        }
      }
      &__helper {
        cursor: pointer;
        display: block;
        align-self: flex-end;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 20px;
        color: #22428f;
        margin: 16px 0 8px 0;
        @include respond(smaller) {
          transform: translateY(105px);
        }
      }
    }
    &__input {
      textarea {
        line-height: 24px;
      }
    }
  }
  &__action {
    align-self: flex-end !important;
    button {
      background-color: #f8fbff;
      border-radius: 16px;
      justify-content: space-between !important;
      padding: 8px 10px !important;
      gap: 6px;
      &:hover {
        background-color: #f8fbff !important;
      }
      span {
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 19px !important;
        color: #22428f !important;
        text-transform: none !important;
      }
      svg path {
        fill: #22428f;
      }
      &:disabled {
        opacity: 0.4;
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    gap: 16px;
    &__action {
      width: 100%;
      background: #ffffff;
      border: 1px solid #e2e4e8;
      box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      padding: 14px 24px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 140%;
      color: #22428f;
      text-align: center;
    }
  }
}
