@import './css/style.scss';

.loader {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.sk-cube-grid {
  width: 40px;
  height: 40px;
  margin: 100px auto;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: #003895;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}

.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }

  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }

  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

.MuiSnackbarContent-message {
  width: 100%;
}

.box-model {
  cursor: pointer;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &.active {
    border-top: 1px solid #e2e4e8;
    border-right: 1px solid #e2e4e8;
    border-left: 1px solid #e2e4e8;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &.active::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 1px;
    background-image: repeating-linear-gradient(
      to right,
      #e2e4e8 0,
      #e2e4e8 calc(100% / 20),
      transparent calc(100% / 20),
      transparent calc(200% / 20)
    );
  }

  &:not(.active) {
    border-bottom: 1px solid #e2e4e8; /* Or any other color for inactive boxes */
  }
}

.box-model-wrap {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 500px;
}

.box-model-item {
  // border: 1px solid #e2e4e8;
  border-left: 1px solid #e2e4e8;
  border-right: 1px solid #e2e4e8;
  border-bottom: 1px solid #e2e4e8;
  box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
  // border-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #fff;
  &__wrap {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    @include respond(mini-mobile) {
      padding: 16px;
    }
  }

  &__weight-wrapper {
    width: 100%;
    margin-top: 30px;
    padding: 32px 24px;
    gap: 16px;
    @include respond(mini-mobile) {
      padding: 16px;
    }

    .input {
      margin-bottom: 30px;
    }

    label {
      margin-bottom: 8px !important;
      font-style: normal;
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 22px !important;
      color: #0f1414;
    }
  }
  &__action {
    align-self: flex-end;
    button {
      cursor: pointer;
      background-color: rgba(240, 93, 61, 0.05);
      border-radius: 36px;
      justify-content: space-between !important;
      padding: 8px 10px !important;
      gap: 6px;
      &:hover {
        background-color: rgba(240, 93, 61, 0.05) !important;
      }
      span {
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 19px !important;
        color: #f05d3d !important;
        text-transform: none !important;
      }
      svg path {
        fill: #f05d3d;
      }
    }
  }
  &__grid {
    display: flex;
    flex-direction: column;
    .category-helper {
      align-self: flex-start !important;
      margin: 0;
      margin-bottom: 11px;

      @include respond(1199px) {
        align-self: flex-end !important;
        margin-bottom: 0 !important;
      }
    }
    .web-alert {
      display: block;

      @include respond(1199px) {
        display: none;
      }
    }
    .mobile-alert {
      display: none;
      margin-left: 10px;

      @include respond(1199px) {
        display: block;
      }
    }
    &__helper {
      position: relative;
      cursor: pointer;
      display: block;
      align-self: flex-end;
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 18px;
      color: #22428f;
      margin: 16px 0 8px 0;
      margin-bottom: -11px;

      @include respond(smaller) {
        transform: translateY(105px);
        margin-bottom: 5px;
      }
    }
  }
  &__input {
    label {
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 12px !important;
      line-height: 20px !important;
      color: #0f1414 !important;
      margin-bottom: 4px !important;
    }
    textarea {
      line-height: 24px;
    }
  }

  &__alert {
    svg,
    .MuiAlert-icon {
      display: none;
    }

    aside {
      gap: 8px;
      display: flex;
      font-weight: 500;
      align-items: flex-start;

      &::before {
        // content: url('public/static/images/svg/info-blue.svg');
        display: block;
        width: 1em;
        height: 1em;
        margin-top: 4px;
      }
    }
  }
}

.box-model-label {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #1d1d1d;
  margin-bottom: 6px;
}

.box-model-grid {
  display: flex;
  flex-direction: column;
  .category-helper {
    align-self: flex-start !important;
    margin: 0;
    margin-bottom: 11px;

    @include respond(1199px) {
      align-self: flex-end !important;
      margin-bottom: 0 !important;
    }
  }
  .web-alert {
    display: block;

    @include respond(1199px) {
      display: none;
    }
  }
  .mobile-alert {
    display: none;
    margin-left: 10px;

    @include respond(1199px) {
      display: block;
    }
  }
  &__helper {
    position: relative;
    cursor: pointer;
    display: block;
    align-self: flex-end;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    color: #22428f;
    margin: 16px 0 8px 0;
    margin-bottom: -11px;

    @include respond(smaller) {
      transform: translateY(105px);
      margin-bottom: 5px;
    }
  }
}
.add-box-style {
  border-bottom: 1px solid #e2e4e8;
  flex-grow: 1;
}

.new-box {
  position: relative;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: #22428f dashed 1px;
  border-radius: 8px;
  margin: 20px;
  cursor: pointer;
  width: 130px;
  height: 130px;

  p {
    color: #22428f;
    font-size: 12px;
  }

  // &::after {
  //   content: '';
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   z-index: -1; /* Ensure it's behind the container content */
  //   background-image: repeating-linear-gradient(
  //     to right,
  //     #22428f 0,
  //     #22428f 1px,
  //     /* Adjust the thickness of the border */ #22428f 1px,
  //     /* Adjust the thickness of the border */ #22428f calc(100% - 1px),
  //     /* Adjust the thickness of the border */ #22428f calc(100% - 1px)
  //       /* Adjust the thickness of the border */
  //   );
  // }
}
