.submit-button {
  font-weight: 600;
  background-color: $buttonColor;
  outline: none;
  border: none;
  color: white;
  border-radius: 5px;
  margin: auto !important;
  margin-top: 1rem;
  transition: all 0.3s;
  width: 100%;
  display: block;

  &:hover {
    cursor: pointer;
    background-color: $buttonColorDark;
  }
}
